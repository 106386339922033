import React, { useState, useRef, useEffect } from 'react';
import { Underline, Text, Flex } from '@strikelabs/luna';
import PropTypes from 'prop-types';
import { useClickAway } from 'react-use';
import { identifier } from '@strikelabs/pax';
import useInView from 'hooks/useInView';

import {
  InputWrapper,
  ResultsContainer,
  StyledAddressList,
  Input,
} from './style';

const PostcodeForm = ({
  value,
  handleSelect,
  handleChange,
  show,
  setShow,
  results,
  loading,
  background,
  isV2,
}) => {
  const { ref, inView } = useInView(true);
  const containerRef = useRef(null);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (!value.length) {
      setShow(false);
    }
  }, [value, setShow]);

  const onSelectResult = result => {
    if (focused) {
      setFocused(false);
    }

    handleSelect(result);
    setShow(false);
  };

  useClickAway(containerRef, () => {
    setShow(false);
  });

  const handleFocus = () => {
    if (value > 2) {
      setShow(true);
    }

    setFocused(true);
  };

  return (
    <Flex width={1} flexDirection="column" alignItems="flex-start">
      <InputWrapper ref={containerRef} background={background}>
        <Input
          onFocus={handleFocus}
          autoComplete="off"
          onBlur={() => setFocused(false)}
          id="location"
          name="location"
          onChange={e => handleChange(e.target.value)}
          placeholder="Town or postcode"
          value={value}
          {...identifier({
            name: 'location-input-container',
            requireId: false,
          })}
        />
        <ResultsContainer show={show && !loading}>
          <StyledAddressList
            addresses={results}
            handleSelect={onSelectResult}
          />
        </ResultsContainer>
      </InputWrapper>
      <Text
        as="h3"
        ext
        color={background === 'dark' ? 'white' : 'inherit'}
        mt={4}
        mb={!isV2 && 5}
        ref={ref}
      >
        Search over{' '}
        <Underline weight="p" active={inView}>
          7,000
        </Underline>{' '}
        homes
      </Text>
    </Flex>
  );
};

PostcodeForm.propTypes = {
  value: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(PropTypes.shape).isRequired,
  background: PropTypes.oneOf(['light', 'dark', 'white']),
  isV2: PropTypes.bool,
};

PostcodeForm.defaultProps = {
  background: 'dark',
  isV2: false,
};

export default PostcodeForm;
