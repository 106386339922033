import styled from 'styled-components';
import { Text, Flex } from '@strikelabs/luna';
import { rem } from 'polished';

import { Container } from 'components/common';

export const HeroContainer = styled(Container)`
  margin: ${({ theme, cookieDismissed }) =>
    `${cookieDismissed ? theme.spacing.md : theme.spacing.xl} auto 0`};
  padding: 0;

  ${({ theme }) => theme.media.smallTablet} {
    margin: ${({ theme, cookieDismissed }) =>
      `${cookieDismissed ? theme.spacing.lg : theme.spacing.xl} auto 0`};
    padding: ${({ theme }) => `0 ${theme.defaults.gutterSpacing}`};
  }

  ${({ theme }) => theme.media.desktop} {
    margin: ${({ theme }) => `${theme.spacing.md} auto 0`};
    position: relative;
  }
`;

export const ColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    align-items: center;
  }
`;

export const ContentContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => `0 ${theme.defaults.gutterSpacing}`};

  ${({ theme }) => theme.media.smallTablet} {
    padding: 0;
  }

  ${({ theme }) => theme.media.desktop} {
    padding-right: ${({ theme }) => theme.spacing.lg};
    width: 50%;
    justify-content: space-between;
  }
`;

export const ImageContainer = styled(Flex)`
  height: ${rem(250)};
  width: 100%;
  background-image: url(${({ heroImage }) => heroImage});
  background-position: ${({ backgroundPosition }) => backgroundPosition[0]};
  background-size: cover;

  ${({ theme }) => theme.media.smallTablet} {
    height: ${rem(380)};
  }

  ${({ theme }) => theme.media.desktop} {
    height: ${rem(620)};
    width: 50%;
    background-position: ${({ backgroundPosition }) => backgroundPosition[1]};
  }
`;

export const PortalWrapper = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.rg};
  margin-bottom: ${({ theme }) => theme.spacing.rg};

  ${({ theme }) => theme.media.smallTablet} {
    justify-content: flex-start;
  }

  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 0;
    margin-top: ${({ theme }) => theme.spacing.lg};
  }

  ${({ theme }) => theme.media.giant} {
    padding-right: ${({ theme }) => theme.spacing.xl};
    justify-content: space-between;
  }
`;

export const Subtitle = styled(Text)`
  display: none;

  ${({ theme }) => theme.media.desktop} {
    display: block;
  }
`;

export const MobilePostcodeAndPortalContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.defaults.gutterSpacing}`};
  transform: translateY(-${rem(55)});
  margin-bottom: -${rem(55)};
`;

export const ZooplaLogo = styled.img`
  width: ${rem(76)};
  margin-top: ${({ theme }) => theme.spacing.sm};

  ${({ theme }) => theme.media.smallTablet} {
    width: ${rem(91)};
    margin-top: ${({ theme }) => theme.spacing.xs};
  }

  ${({ theme }) => theme.media.desktop} {
    width: ${rem(105)};
    margin-top: 0;
  }
`;
