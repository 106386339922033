import React from 'react';
import {
  Text,
  Flex,
  Box,
  Icon,
  Button,
  ShowBreakpoint,
} from '@strikelabs/luna';

import Link from 'components/Link';

import { HandIcon, SignIcon, HouseIcon } from './Icons';
import {
  Outer,
  Inner,
  ColumnWrapper,
  ContentContainer,
  ImageAndPhoneContainer,
  ImageContainer,
  PhoneContainer,
  HandIconContainer,
  SignIconContainer,
  HouseIconContainer,
} from './style';

const ByYourSide = () => {
  return (
    <Outer>
      <Inner>
        <ColumnWrapper>
          <ImageAndPhoneContainer>
            <ImageContainer />
            <PhoneContainer>
              <HandIconContainer>
                <HandIcon />
              </HandIconContainer>
              <SignIconContainer>
                <SignIcon />
              </SignIconContainer>
              <HouseIconContainer>
                <HouseIcon />
              </HouseIconContainer>
            </PhoneContainer>
          </ImageAndPhoneContainer>
          <ContentContainer>
            <Text as="h3" styleType="h2" ext mb={[2, 4]}>
              We&apos;re by your side, all the way until the keys are in your
              hand
            </Text>
            <Flex mb={3}>
              <Box mt={0} mr={[1, 1, 1, 1, 0]}>
                <Icon type="check" width="18" />
              </Box>
              <Text as="p" ext>
                Expert valuations, packed with data and insights about your area
              </Text>
            </Flex>
            <Flex mb={3}>
              <Box mt={0} mr={[2, 2, 2, 2, 1]}>
                <Icon type="check" width="18" />
              </Box>
              <Text as="p" ext>
                Get your property in front of tens of millions of potential
                buyers on major property portals
              </Text>
            </Flex>
            <Flex mb={3}>
              <Box mt={0} mr={[2, 2, 2, 2, 1]}>
                <Icon type="check" width="18" />
              </Box>
              <Text as="p" ext>
                Manage viewings, offers, and communicate directly with buyers in
                our app &mdash; or have a Strike agent do the legwork
              </Text>
            </Flex>
            <Flex mb={3}>
              <Box mt={0} mr={[2, 2, 2, 2, 1]}>
                <Icon type="check" width="18" />
              </Box>
              <Text as="p" ext>
                Professional negotiation to make sure you get the best possible
                price for your home
              </Text>
            </Flex>
            <ShowBreakpoint breakpoint="desktop">
              <Box mt={4}>
                <Link to="/book-valuation">
                  <Button>Book a free valuation</Button>
                </Link>
              </Box>
            </ShowBreakpoint>
          </ContentContainer>
        </ColumnWrapper>
      </Inner>
    </Outer>
  );
};

export default ByYourSide;
