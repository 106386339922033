import { useMemo, useState } from 'react';
import { usePlaceLookup, useWebGet, SUCCESS } from '@strikelabs/vega';
import { useDebounce, usePrevious } from 'react-use';

const useLocationSearch = ({ query, setShow, show }) => {
  const [locationName, setLocationName] = useState('');
  const prevValue = usePrevious(query);
  const { autoComplete, autoCompleteState, placeDetails, placeDetailsState } =
    usePlaceLookup({
      baseUrl: process.env.GATSBY_API_URL,
      useGetHook: useWebGet,
    });

  const handleSelect = result => {
    setLocationName(result.text);
    placeDetails(result.id);
  };

  const formatPlaceDetails = useMemo(() => {
    if (placeDetailsState.status === SUCCESS && placeDetailsState.data) {
      const { lat, lng } = placeDetailsState.data.result.geometry.location;
      return { lat: Number(lat), lng: Number(lng), name: locationName };
    }

    return null;
  }, [placeDetailsState.status]);

  const results = useMemo(() => {
    if (!autoCompleteState.data.predictions) {
      return;
    }

    return autoCompleteState.data?.predictions?.map(prediction => ({
      text: prediction.description.split(', UK')[0],
      id: prediction.place_id,
    }));
  }, [autoCompleteState.data]);

  useDebounce(
    () => {
      if (!query || autoCompleteState.loading || prevValue === undefined) {
        setShow(false);
        return;
      }

      if (!show) {
        setShow(true);
      }
      autoComplete(query);
    },
    250,
    [query]
  );

  return {
    results: results || [],
    handleSelect,
    formatPlaceDetails,
    show,
    loading: autoCompleteState.loading || placeDetailsState.loading,
  };
};

export default useLocationSearch;
