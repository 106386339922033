import React, { useState, useEffect } from 'react';
import { Box, Text } from '@strikelabs/luna';

import useLocationSearch from 'components/PropertySearch/hooks/useLocationSearch';
import PostcodeForm from 'components/PropertySearch/PostcodeForm';
import Image from 'components/Image';

import {
  Outer,
  Inner,
  ColumnWrapper,
  ContentContainer,
  MasonryContainer,
  ImageContainer,
  Breakpoint,
} from './style';

const PropertySearch = () => {
  const [show, setShow] = useState(false);
  const [value, onChange] = useState('');

  const { results, handleSelect, formatPlaceDetails, loading } =
    useLocationSearch({
      query: value,
      setShow: setShow,
      showing: show,
    });

  useEffect(() => {
    if (formatPlaceDetails) {
      const { lat, lng, name } = formatPlaceDetails;
      window.location.href = `/property-for-sale/search?name=${name}&lat=${lat}&lng=${lng}`;
    }
  }, [formatPlaceDetails]);

  return (
    <Outer>
      <Inner>
        <ColumnWrapper>
          <MasonryContainer>
            <ImageContainer>
              <Image
                src="property-search-v2-left.png"
                alt="Victorian terraced house"
                objectFit="cover"
              />
            </ImageContainer>
            <ImageContainer right>
              <Image
                src="property-search-v2-right.png"
                alt="Modern 3-level duplex"
                objectFit="cover"
              />
            </ImageContainer>
          </MasonryContainer>
          <ContentContainer>
            <Box mb={[3, 3, 3, 4]}>
              <Text as="h2" ext>
                See what&apos;s on Strike
                <Breakpoint /> with our property search
              </Text>
            </Box>
            <PostcodeForm
              value={value}
              handleChange={onChange}
              handleSelect={handleSelect}
              show={show}
              loading={loading}
              setShow={setShow}
              results={results}
              background="white"
              isV2
            />
          </ContentContainer>
        </ColumnWrapper>
      </Inner>
    </Outer>
  );
};

export default PropertySearch;
