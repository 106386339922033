import styled from 'styled-components';
import { Flex } from '@strikelabs/luna';
import { rem } from 'polished';

import { Container } from 'components/common';

export const Outer = styled.div`
  background: ${({ theme }) => theme.colors.backgroundLight};
  padding-top: ${({ theme }) => theme.spacing.xl};
  padding-bottom: ${({ theme }) => theme.spacing.xl};

  ${({ theme }) => theme.media.desktop} {
    padding-top: ${({ theme }) => theme.spacing.xxl};
    padding-bottom: ${({ theme }) => theme.spacing.xxl};
  }
`;

export const Inner = styled(Container)`
  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 0;
  }
`;

export const ColumnWrapper = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    align-items: center;
  }
`;

export const ContentContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
    padding-left: ${({ theme }) => theme.spacing.xl};
  }
`;

export const MasonryContainer = styled(Flex)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  ${({ theme }) => theme.media.smallTablet} {
    margin-bottom: ${({ theme }) => theme.spacing.xl};
  }

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
    margin-bottom: 0;
  }
`;

export const Breakpoint = styled.br`
  display: none;

  ${({ theme }) => theme.media.smallTablet} {
    display: block;
  }
`;

export const ImageContainer = styled.div`
  height: ${rem(237)};
  width: ${rem(139)};
  transform: ${({ right, theme }) =>
    right && `translateY(${theme.spacing.lg})`};
  margin-left: ${({ right, theme }) => right && theme.spacing.rg};
  margin-bottom: ${({ right, theme }) => right && theme.spacing.rg};

  ${({ theme }) => theme.media.smallTablet} {
    height: ${rem(256)};
    width: ${rem(182)};
    transform: ${({ right, theme }) =>
      right && `translateY(${theme.spacing.rg})`};
  }

  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 0;
    height: ${rem(343)};
    width: ${rem(244)};
  }
`;
