import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/Image';

import { Container, GuideTitle, ImageContainer } from './style';

const Guide = ({ title, image, link, imageAlt }) => {
  return (
    <Container to={link}>
      <GuideTitle>{title}</GuideTitle>
      {image && (
        <ImageContainer>
          <Image src={image} alt={imageAlt} style={{ height: '100%' }} />
        </ImageContainer>
      )}
    </Container>
  );
};

Guide.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  link: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
};

Guide.defaultProps = {
  image: null,
  imageAlt: 'Guide',
};

export default Guide;
