import React from 'react';
import { Text, useIsMedia } from '@strikelabs/luna';
import PropTypes from 'prop-types';

import Image from 'components/Image';

const GrowthImage = 'illustrations/growth.png';
const SellingImage = 'illustrations/selling.png';
const MovingImage = 'illustrations/moving.png';
const ViewingImage = 'illustrations/viewing.png';

import {
  Outer,
  Inner,
  IconAndTextContainer,
  IconContainer,
  IconAndTextBox,
} from './style';

const ChangingWhatItMeans = ({ bgColor }) => {
  const { isMobile } = useIsMedia();

  return (
    <Outer bgColor={bgColor}>
      <Inner>
        <Text as="h2" ext mb={4}>
          We&apos;re changing what it means to be an estate agent
        </Text>
        <Text as="p" ext mb={[4, 4, 4, 5]}>
          &mdash; so join the tens of thousands who have found a better way to
          sell their home.
        </Text>
        <IconAndTextContainer>
          <IconAndTextBox>
            <IconContainer type="growth" isMobile={isMobile}>
              <Image src={GrowthImage} alt="Growth illustration" />
            </IconContainer>
            <Text as="p" ext>
              We&apos;re the fastest-growing estate agent in the UK
            </Text>
          </IconAndTextBox>
          <IconAndTextBox>
            <IconContainer type="selling" isMobile={isMobile}>
              <Image src={SellingImage} alt="Selling illustration" />
            </IconContainer>
            <Text as="p" ext>
              We&apos;ve sold over &pound;5.02bn since launch
            </Text>
          </IconAndTextBox>
          <IconAndTextBox>
            <IconContainer type="moving" isMobile={isMobile}>
              <Image src={MovingImage} alt="Moving illustration" />
            </IconContainer>
            <Text as="p" ext>
              We helped 15,026 people move this year
            </Text>
          </IconAndTextBox>
          <IconAndTextBox>
            <IconContainer type="viewing" isMobile={isMobile}>
              <Image src={ViewingImage} alt="Viewings illustration" />
            </IconContainer>
            <Text as="p" ext>
              We get 628 viewings requested every day, that&apos;s one every 2
              minutes
            </Text>
          </IconAndTextBox>
        </IconAndTextContainer>
      </Inner>
    </Outer>
  );
};

ChangingWhatItMeans.propTypes = {
  bgColor: PropTypes.oneOf(['white', 'backgroundLight']),
};

ChangingWhatItMeans.defaultProps = {
  bgColor: 'white',
};

export default ChangingWhatItMeans;
