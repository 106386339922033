import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'gatsby';
import { Text } from '@strikelabs/luna';

export const Container = styled(Link)`
  display: flex;
  text-decoration: none;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.rg} ${theme.spacing.rg} 0`};
  background: ${({ theme }) => theme.colors.borderLight};
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.colors.backgroundDark};
    cursor: grab;
  }

  &:hover > p {
    color: ${({ theme }) => theme.colors.lightIcon};
  }

  &:hover > h3 {
    color: ${({ theme }) => theme.colors.lightIcon};
  }

  ${({ theme }) => theme.media.smallTablet} {
    width: 48%;
  }

  ${({ theme }) => theme.media.desktop} {
    padding: ${({ theme }) => `${theme.spacing.lg} ${theme.spacing.lg} 0`};
  }

  ${({ theme }) => theme.media.giant} {
    width: 32%;
  }

  &:hover > div {
    top: ${({ theme }) => theme.spacing.sm};
  }
`;

export const GuideTitle = styled(Text).attrs({ as: 'h3' })`
  ${({ theme }) => theme.media.desktop} {
    font-size: ${rem(26)};
    line-height: ${rem(30)};
    font-weight: 400;
  }

  ${({ theme }) => theme.media.giant} {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

export const ImageContainer = styled.div`
  margin-top: auto;
  position: relative;
  top: ${({ theme }) => theme.spacing.md};
  height: ${rem(164)};
  width: 100%;

  ${({ theme }) => theme.media.desktop} {
    height: ${rem(223)};
    top: ${({ theme }) => theme.spacing.rg};
  }
`;
