import styled from 'styled-components';
import { rem } from 'polished';
import { AddressList } from '@strikelabs/luna';

export const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sm};

  ${({ theme }) => theme.media.tablet} {
    margin-top: 0;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;

  ${({ theme }) => theme.media.smallTablet} {
    max-width: ${rem(342)};
  }

  ${({ theme }) => theme.media.desktop} {
    max-width: ${rem(426)};
  }

  ${({ theme }) => theme.media.desktop} {
    min-width: ${rem(342)};
  }

  span {
    line-height: ${rem(25)};
  }

  input {
    border: ${({ theme, background }) =>
      background !== 'dark' ? `1px solid ${theme.colors.border}` : 'none'};
    transition: none;
    &:focus {
      border: ${({ theme, background }) =>
        background !== 'dark' ? `1px solid ${theme.colors.border}` : 'none'};
    }
  }
`;

export const ResultsContainer = styled.div`
  position: absolute;
  top: calc(100% - 1px);
  left: 0px;
  right: 0px;
  z-index: 50;
  background: white;
  width: 100%;
  transition: opacity 0.1s;
  border-radius: 0 0 ${rem(2)} ${rem(2)};
  display: ${({ show }) => (show ? 'block' : 'none')};
  pointer-events: ${({ show }) => (show ? 'default' : 'none')};
`;

export const StyledAddressList = styled(AddressList)`
  max-height: 25vh;
`;

export const Input = styled.input`
  appearance: none;
  width: 100%;
  height: ${rem(50)};
  padding: ${rem(20)};
  padding-left: ${({ theme }) => theme.atomSpacing.lg};
  border: ${({ theme }) => `${rem(1)} solid ${theme.colors.border}`};
  border-radius: ${rem(4)};
  line-height: ${rem(25)};
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.colors.bodyText};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px #fff inset;
  }

  &:focus {
    outline: 0;
    border: ${({ theme }) => `${rem(1)} solid ${theme.colors.statusFocus}`};
  }
`;
