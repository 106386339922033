import styled from 'styled-components';
import { Flex } from '@strikelabs/luna';

import { Container } from 'components/common';

const getWidth = (type, isMobile) => {
  const MOBILE_WIDTH = '86px';
  switch (type) {
    case 'growth':
      return isMobile ? '66px' : '88px';
    case 'selling':
      return isMobile ? MOBILE_WIDTH : '128px';
    case 'moving':
      return isMobile ? MOBILE_WIDTH : '136px';
    case 'viewing':
      return isMobile ? MOBILE_WIDTH : '106px';
  }
};

export const Outer = styled.div`
  padding-top: ${({ theme }) => theme.spacing.xl};
  padding-bottom: ${({ theme }) => theme.spacing.xl};
  background: ${({ theme, bgColor }) => theme.colors[bgColor]};

  ${({ theme }) => theme.media.desktop} {
    padding-top: ${({ theme }) => theme.spacing.xxl};
    padding-bottom: ${({ theme }) => theme.spacing.xxl};
  }
`;

export const Inner = styled(Container)`
  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 0;
  }
`;

export const IconAndTextContainer = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const IconContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  width: ${({ type, isMobile }) => getWidth(type, isMobile)};
`;

export const IconAndTextBox = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 0;
    max-width: 20%;
    text-align: left;
    align-items: flex-start;
  }
`;
