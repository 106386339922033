import styled from 'styled-components';
import { rem } from 'polished';

export const Inner = styled.div`
  min-width: ${rem(200)};
  overflow-x: hidden;
  width: 100%;
  padding: ${rem(38)} ${rem(20)} ${rem(20)};
  ${({ theme }) => theme.media.tablet} {
    max-width: ${rem(500)};
    padding: ${rem(52)};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  margin-left: ${rem(-52)};
  align-items: center;
`;

export const TextContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  position: relative;
  margin-left: ${({ theme }) => theme.spacing.sm};
  width: 50%;
`;

export const Image = styled.img`
  flex-shrink: 0;
  width: calc(58%);
`;

export const Body = styled.p`
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  ${({ theme }) => theme.media.tablet} {
    line-height: ${rem(26)};
    font-size: ${rem(16)};
  }
`;

export const Content = styled.div`
  padding-top: ${({ theme }) => theme.spacing.rg};
`;
