import React from 'react';
import {
  Text,
  ShowBreakpoint,
  HideBreakpoint,
  Box,
  Flex,
  useIsMedia,
} from '@strikelabs/luna';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import Image from 'components/Image';
import Button from 'components/common/Button';
import TrustBox from 'components/TrustBox';
import EstasThreePeat from 'images/logos/estas-three-peat-black.png';

import {
  Outer,
  Inner,
  ColumnWrapper,
  ContentContainer,
  ImageContainer,
  ImageAndESTASContainer,
  ESTASContainer,
} from './style';

const ESTAS = ({ title, subtitle, noCta, bgColor }) => {
  const { isMobile } = useIsMedia();

  return (
    <Outer bgColor={bgColor}>
      <Inner>
        <ColumnWrapper>
          <ContentContainer>
            <Text as="h3" styleType="h2" ext mb={3}>
              {title}
            </Text>
            <Text as="p" ext mb={3}>
              {subtitle}
            </Text>
            {!noCta ? (
              <>
                <HideBreakpoint breakpoint="smallTablet">
                  <Flex mb={5} width="160px">
                    <img
                      src={EstasThreePeat}
                      alt="Estas triple winners"
                      width="100%"
                    />
                  </Flex>
                </HideBreakpoint>
                <ShowBreakpoint breakpoint="smallTablet">
                  <Link to="/more">
                    <Button styleType="blackVariant">Find out more</Button>
                  </Link>
                </ShowBreakpoint>
              </>
            ) : (
              <Flex
                width={1}
                flexDirection={['column', 'row-reverse', 'row']}
                alignItems={['center', 'center', 'flex-end']}
                justifyContent={[
                  'space-between',
                  'space-between',
                  'flex-start',
                ]}
              >
                <Flex
                  flexDirection="column"
                  mb={[1, 0]}
                  mr={['0px', '0px', 4]}
                  alignItems={['center', 'flex-start']}
                >
                  <Box ml="-16px">
                    <TrustBox />
                  </Box>
                </Flex>
                <Flex alignItems="center" width={isMobile ? '140px' : '220px'}>
                  <img
                    src={EstasThreePeat}
                    alt="Estas triple winners"
                    width="100%"
                  />
                </Flex>
              </Flex>
            )}
          </ContentContainer>
          <ImageAndESTASContainer>
            {!noCta && (
              <ESTASContainer>
                <Box width="160px">
                  <img
                    src={EstasThreePeat}
                    alt="Estas triple winners"
                    width="100%"
                  />
                </Box>
              </ESTASContainer>
            )}
            <ImageContainer>
              <Image
                src="illustration-holding-signs.png"
                alt="Holding signs illustration"
                objectFit="cover"
              />
            </ImageContainer>
          </ImageAndESTASContainer>
          {!noCta && (
            <HideBreakpoint breakpoint="smallTablet">
              <Link to="/more">
                <Button styleType="blackVariant" fullWidth>
                  Find out more
                </Button>
              </Link>
            </HideBreakpoint>
          )}
        </ColumnWrapper>
      </Inner>
    </Outer>
  );
};

ESTAS.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  noCta: PropTypes.bool,
  bgColor: PropTypes.oneOf(['white', 'backgroundLight']),
};

ESTAS.defaultProps = {
  noCta: false,
  bgColor: 'backgroundLight',
};

export default ESTAS;
