import React from 'react';
import {
  Box,
  Icon,
  Flex,
  Text,
  ShowBreakpoint,
  Button,
  HideBreakpoint,
} from '@strikelabs/luna';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import TrustBox from 'components/TrustBox';
import Link from 'components/Link';
import Zoopla from 'images/logos/zoopla.png';
import { useGlobalStore } from 'provider';
import defaultHeroImage from 'images/homepage-hero-variant.jpg';
import {
  HeroContainer,
  ColumnWrapper,
  ContentContainer,
  ImageContainer,
  PortalWrapper,
  Subtitle,
  MobilePostcodeAndPortalContainer,
  ZooplaLogo,
} from './style';

const Hero = ({
  hidePortals,
  subtitle,
  children,
  heroImage,
  backgroundPosition,
}) => {
  const {
    state: { cookieDismissed },
  } = useGlobalStore();

  const textTransitionConfig = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.5, duration: 1.25 } },
  };

  const portalTransitionConfig = {
    initial: { y: 50, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: { delay: 0.75, duration: 1 },
    },
  };

  return (
    <HeroContainer cookieDismissed={cookieDismissed}>
      <ColumnWrapper>
        <ContentContainer>
          <motion.div
            initial={{ ...textTransitionConfig.initial }}
            animate={{ ...textTransitionConfig.animate }}
          >
            <Text as="h1" ext mb={[null, null, 0, null]}>
              {children}
            </Text>
            {subtitle && (
              <HideBreakpoint breakpoint="smallTablet">
                <Text as="h3" ext mb={4} mt={1}>
                  {subtitle}
                </Text>
              </HideBreakpoint>
            )}
          </motion.div>
          <ShowBreakpoint breakpoint="smallTablet">
            <Box>
              {subtitle && (
                <motion.div
                  initial={{ ...textTransitionConfig.initial }}
                  animate={{ ...textTransitionConfig.animate }}
                >
                  <Subtitle
                    as="h3"
                    ext
                    mb={[null, null, null, null, 4]}
                    mt={[1, 1, null]}
                  >
                    {subtitle}
                  </Subtitle>
                </motion.div>
              )}
              <motion.div
                initial={{ ...portalTransitionConfig.initial }}
                animate={{ ...portalTransitionConfig.animate }}
              >
                <Box mt={[null, null, 1, 1, 4]} mb={[null, null, 3, 3, null]}>
                  <Link to="/book-valuation">
                    <Button id="hero-cta">Book a valuation</Button>
                  </Link>
                </Box>
                {!hidePortals && (
                  <PortalWrapper>
                    <Flex alignItems="center">
                      <TrustBox />
                    </Flex>
                    <Box mr={1} mt={0}>
                      <Icon type="rightmove" width="130px" />
                    </Box>
                    <ZooplaLogo src={Zoopla} />
                  </PortalWrapper>
                )}
              </motion.div>
            </Box>
          </ShowBreakpoint>
        </ContentContainer>
        <ImageContainer
          heroImage={heroImage}
          backgroundPosition={backgroundPosition}
        />
        <HideBreakpoint breakpoint="smallTablet">
          <MobilePostcodeAndPortalContainer>
            <Link to="/book-valuation">
              <Button id="hero-cta" fullWidth>
                Book a valuation
              </Button>
            </Link>
            {!hidePortals && (
              <PortalWrapper>
                <Flex alignItems="center">
                  <Box mr={1}>
                    <Icon type="trustpilotType" width="125px" />
                  </Box>
                  <Box mr={2} mt={0}>
                    <Icon type="trustpilotStars" width="96px" />
                  </Box>
                </Flex>
                <Box mr={2} mt={1}>
                  <Icon type="rightmove" width="130px" />
                </Box>
                <ZooplaLogo src={Zoopla} />
              </PortalWrapper>
            )}
          </MobilePostcodeAndPortalContainer>
        </HideBreakpoint>
      </ColumnWrapper>
    </HeroContainer>
  );
};

Hero.propTypes = {
  hidePortals: PropTypes.bool,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  heroImage: PropTypes.string,
  backgroundPosition: PropTypes.arrayOf(
    PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'center'])
  ),
};

Hero.defaultProps = {
  hidePortals: false,
  subtitle: null,
  heroImage: defaultHeroImage,
  backgroundPosition: ['center', 'center'],
};
export default Hero;
