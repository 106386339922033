import styled from 'styled-components';
import { Flex } from '@strikelabs/luna';
import { rem } from 'polished';

import HouseImage from 'images/by-your-side-house.png';
import PhoneHub from 'images/iphone-hub.png';

import { Container } from 'components/common';

export const Outer = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.xl};

  ${({ theme }) => theme.media.desktop} {
    padding-top: ${({ theme }) => theme.spacing.xxl};
    padding-bottom: ${({ theme }) => theme.spacing.xxl};
  }
`;

export const Inner = styled(Container)`
  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 0;
  }
`;

export const ColumnWrapper = styled(Flex)`
  flex-direction: column;
  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    align-items: center;
  }
`;

export const ContentContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
    padding-left: ${({ theme }) => theme.spacing.xl};
  }
`;

export const ImageAndPhoneContainer = styled(Flex)`
  position: relative;
  width: 90%;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  margin-left: ${({ theme }) => `-${theme.defaults.gutterSpacing}`};

  ${({ theme }) => theme.media.smallTablet} {
    margin-left: 0;
    width: 80%;
    margin-bottom: ${({ theme }) => theme.spacing.lg};
  }

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
    margin-bottom: 0;
  }
`;

export const ImageContainer = styled.div`
  width: ${rem(258)};
  height: ${rem(321)};
  background-image: url(${HouseImage});
  background-position: center;
  background-size: cover;
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  ${({ theme }) => theme.media.smallTablet} {
    width: ${rem(462)};
    margin-bottom: ${({ theme }) => theme.spacing.xxl};
  }

  ${({ theme }) => theme.media.desktop} {
    width: ${rem(476)};
    height: ${rem(545)};
    margin-bottom: 0;
  }
`;

export const PhoneContainer = styled.div`
  position: absolute;
  z-index: 150;
  width: ${rem(167)};
  height: ${rem(337)};
  justify-self: flex-end;
  translate: ${({ theme }) => `translateY(${theme.spacing.xl})`};
  background: url(${PhoneHub}) center/contain no-repeat;
  right: 0;
  top: ${({ theme }) => theme.spacing.xl};

  ${({ theme }) => theme.media.smallTablet} {
    right: ${({ theme }) => theme.spacing.xl};
  }

  ${({ theme }) => theme.media.desktop} {
    right: ${({ theme }) => theme.spacing.lg};
    top: unset;
    bottom: ${({ theme }) => `-${theme.spacing.xl}`};
    width: ${rem(256)};
    height: ${rem(517)};
    translate: ${({ theme }) => `translateY(${theme.spacing.xxl})`};
  }
`;

export const HandIconContainer = styled.div`
  position: absolute;
  width: ${rem(55)};
  height: ${rem(36)};
  z-index: 250;
  left: ${({ theme }) => `-${theme.spacing.md}`};
  bottom: ${rem(89)};

  ${({ theme }) => theme.media.desktop} {
    width: ${rem(78)};
    height: ${rem(51)};
    bottom: ${rem(120)};
  }
`;

export const HouseIconContainer = styled.div`
  position: absolute;
  width: ${rem(50)};
  height: ${rem(37)};
  z-index: 250;
  right: ${({ theme }) => `-${theme.spacing.rg}`};
  bottom: ${rem(124)};

  ${({ theme }) => theme.media.desktop} {
    width: ${rem(70)};
    height: ${rem(52)};
    bottom: ${rem(237)};
  }
`;

export const SignIconContainer = styled.div`
  position: absolute;
  width: ${rem(60)};
  height: ${rem(59)};
  z-index: 250;
  right: ${({ theme }) => `-${theme.spacing.lg}`};
  bottom: ${rem(59)};

  ${({ theme }) => theme.media.desktop} {
    width: ${rem(84)};
    height: ${rem(82)};
    bottom: ${rem(143)};
    right: ${({ theme }) => `-${theme.spacing.xl}`};
  }
`;
