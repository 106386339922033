import React from 'react';
import { Modal, Text, Button, Box, useIsMedia } from '@strikelabs/luna';
import PropTypes from 'prop-types';

import FriendsImage from 'images/raf-friends.png';
import Link from 'components/Link';

import {
  Inner,
  TitleContainer,
  TextContainer,
  Body,
  Image,
  Content,
} from './style';

const RafModal = ({ showModal, setShowModal }) => {
  const { isMobile } = useIsMedia();

  return (
    <Modal
      onClose={() => setShowModal(false)}
      isOpen={showModal}
      iconColor="icon"
      pad={false}
    >
      <Inner>
        <TitleContainer>
          <Image src={FriendsImage} alt="recommending to friends" />
          <TextContainer>
            <Text as="h2" ext>
              Ready to go on Strike?
            </Text>
          </TextContainer>
        </TitleContainer>
        <Content>
          <Body>
            Thanks for using your friend&apos;s referral link! We&apos;re here
            to take the stress out of moving &mdash; for everyone. If you decide
            to go with Strike for your house sale or mortgage, you&apos;ll both
            get a &pound;50 Amazon voucher!
          </Body>
          <Box mt={2}>
            <Link to="/book-valuation">
              <Button fullWidth={isMobile}>Book a valuation</Button>
            </Link>
          </Box>
          <Box mt={2}>
            <Link to="/mortgage-advice">
              <Button fullWidth={isMobile}>Book a mortgage appointment</Button>
            </Link>
          </Box>
        </Content>
      </Inner>
    </Modal>
  );
};

RafModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default RafModal;
