import React from 'react';

const SignIcon = () => (
  <svg
    width="85"
    height="82"
    viewBox="0 0 85 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="64"
      y="1.30847"
      width="4.28571"
      height="43.617"
      rx="1.25"
      fill="white"
      stroke="black"
      strokeWidth="1.5"
    />
    <rect
      x="48.5714"
      y="6.54248"
      width="35.1429"
      height="24.4255"
      rx="3"
      fill="#00FF7A"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M61 20.3492L64.0545 23.4709L73.1521 13.9574"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.297 43.6691H35.3292C25.6649 43.2107 20.8327 45.7756 20.8327 51.3638C20.8327 56.9519 23.8972 59.481 30.0261 58.951H41.8524C50.0781 58.4715 54.1909 61.03 54.1909 66.6265C54.1909 72.223 50.151 75.0212 42.0713 75.0212H2.71427"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeDasharray="4 3"
    />
    <ellipse
      cx="5.38998"
      cy="75.9997"
      rx="4.38998"
      ry="4.46782"
      fill="#65EBA6"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SignIcon;
