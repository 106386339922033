import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const TrustBox = ({ theme, scale, templateId, style, props }) => {
  const ref = React.useRef(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      style={{
        transform: `scale(${scale})`,
        maxWidth: '100%',
        ...style,
      }}
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id={templateId}
      data-businessunit-id="55ca14bb0000ff0005820737"
      data-style-height="24px"
      data-style-width="100%"
      data-theme={theme}
      {...props}
    >
      <a
        href="https://uk.trustpilot.com/review/strike.co.uk"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};

TrustBox.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  scale: PropTypes.number,
  templateId: PropTypes.string,
  style: PropTypes.object,
  props: PropTypes.object,
};

TrustBox.defaultProps = {
  theme: 'light',
  scale: 1,
  templateId: '5419b732fbfb950b10de65e5',
};

export default TrustBox;
