import styled from 'styled-components';
import { Flex, Text } from '@strikelabs/luna';
import { rem } from 'polished';

import { Container } from 'components/common';

export const Outer = styled.div`
  background: ${({ theme, bgColor }) => theme.colors[bgColor]};
  padding-top: ${({ theme }) => theme.spacing.xl};
  padding-bottom: ${({ theme, noCta }) => (noCta ? '0px' : theme.spacing.xl)};

  ${({ theme }) => theme.media.desktop} {
    padding-bottom: ${({ theme }) => theme.spacing.xl};
  }
`;

export const Inner = styled(Container)`
  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 0;
  }
`;

export const ColumnWrapper = styled(Flex)`
  flex-direction: column;
  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    align-items: center;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
  }
`;

export const ImageAndESTASContainer = styled(Flex)`
  width: 100%;

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  ${({ theme }) => theme.media.smallTablet} {
    width: 70%;
    margin-bottom: 0;
    align-self: flex-end;
  }

  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    padding-left: ${({ theme }) => theme.spacing.xl};
  }
`;

export const ESTASContainer = styled.div`
  display: none;

  ${({ theme }) => theme.media.smallTablet} {
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }

  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 0;
    position: absolute;
    bottom: ${({ theme }) => theme.spacing.md};
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

export const Caption = styled(Text)`
  font-size: ${rem(6)};
  color: ${({ theme }) => theme.colors.captionText};
  text-transform: uppercase;
  z-index: 2;
  letter-spacing: ${rem(2.5)};
  line-height: ${rem(18)};

  ${({ theme }) => theme.media.tablet} {
    font-size: ${rem(12)};
  }
`;
