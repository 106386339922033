import styled from 'styled-components';
import { Text, Flex, Box } from '@strikelabs/luna';

import { Container } from 'components/common';

export const Outer = styled(Container)`
  margin-bottom: 0;
`;

export const Inner = styled.div`
  margin: ${({ theme }) => `${theme.spacing.xl} 0`};

  ${({ theme }) => theme.media.desktop} {
    margin: ${({ theme }) => `${theme.spacing.xl} 0`};
  }
`;

export const Header = styled(Text).attrs({ as: 'h2', ext: true })`
  margin-bottom: ${({ theme }) => theme.spacing.rg};

  ${({ theme }) => theme.media.desktop} {
    margin-bottom: ${({ theme }) => theme.spacing.lg};
  }
`;

export const Subheader = styled(Text).attrs({ as: 'h3', ext: true })`
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

export const GuideRow = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.media.smallTablet} {
    flex-flow: row wrap;
    justify-content: space-between;
  }
`;

export const ButtonContainer = styled(Box)`
  text-align: center;
`;
