import React from 'react';

const HandIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 80 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5973 25.2783L10.8853 26.8728V47.9191C16.5189 48.8758 28.2326 51.108 30.0183 52.3835C31.8041 53.659 35.2268 52.915 36.7149 52.3835L57.4423 45.6869L77.8509 30.0616C80.4019 23.4288 75.0872 22.621 72.111 23.0462C64.5641 27.1916 49.4065 35.2275 49.1513 34.2071C48.8325 32.9315 35.7582 28.1483 33.2072 26.8728C31.1663 25.8523 26.6169 25.3846 24.5973 25.2783Z"
      fill="white"
    />
    <path
      d="M10.5665 51.7457H1V22.7273H10.5665V51.7457Z"
      fill="#65EBA6"
      stroke="black"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8854 47.9192L30.9751 52.7024C31.9317 53.0213 34.993 53.2764 39.5849 51.7458C44.1769 50.2152 53.1906 47.0688 57.1235 45.687L78.1698 30.0617C79.2328 27.2981 79.6367 21.8983 72.7488 22.4085L49.0894 34.5261M10.8854 26.554L24.5974 25.2785C26.2981 25.0659 30.1748 25.5432 33.2073 26.554C37.0339 27.8296 45.006 31.3373 47.8759 32.9317C50.1719 34.2072 50.7459 41.5416 44.0493 41.2227L25.873 36.1205"
      stroke="black"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.1315 19.3454C47.1315 18.8759 46.7509 18.4954 46.2815 18.4954H37.9037C38.1099 18.2214 38.2964 17.9314 38.4631 17.6256C38.9421 16.7733 39.2069 15.8272 39.2714 14.8024L45.1215 14.7811C45.5898 14.7794 45.9684 14.3993 45.9684 13.9311V11.6771C45.9684 11.4512 45.8785 11.2346 45.7185 11.0751C45.5585 10.9156 45.3416 10.8264 45.1156 10.8272L38.1425 10.85C37.8657 10.3417 37.6606 9.91105 37.5212 9.55469L36.8665 9.81068L37.5212 9.55469C37.3761 9.18355 37.2826 8.6385 37.2826 7.87979C37.2826 6.91263 37.5318 6.30782 37.905 5.92768C38.3042 5.52145 38.8822 5.27541 39.7486 5.27541C40.6154 5.27541 41.2601 5.55006 41.7594 6.06851L41.7594 6.06855L41.7666 6.07584C42.2798 6.59612 42.561 7.28478 42.561 8.22277C42.561 8.69222 42.9415 9.07277 43.411 9.07277H45.935C46.1669 9.07277 46.3888 8.97802 46.5491 8.81048C46.7095 8.64294 46.7944 8.41716 46.7842 8.18548C46.6952 6.15832 46.0925 4.45156 44.9085 3.14588C43.6671 1.75991 41.8009 1.15 39.5011 1.15C38.2147 1.15 37.0488 1.41061 36.0303 1.96174C35.0321 2.49444 34.2523 3.25504 33.7096 4.23368C33.17 5.20675 32.9101 6.2988 32.9101 7.4878C32.9101 8.36566 33.0074 9.17319 33.2148 9.90111L33.2206 9.92049C33.315 10.2243 33.4245 10.5348 33.5488 10.8516H32.2506C31.7812 10.8516 31.4006 11.2322 31.4006 11.7016V13.9556C31.4006 14.425 31.7812 14.8056 32.2506 14.8056H35.0092C35.0121 14.8586 35.0135 14.9101 35.0135 14.96C35.0135 15.8129 34.772 16.5136 34.3058 17.1033L34.3058 17.1033L34.2987 17.1125C33.8235 17.7308 33.177 18.2154 32.329 18.5569C32.0073 18.6865 31.7965 18.9985 31.7965 19.3454V21.7708C31.7965 22.2402 32.1771 22.6208 32.6465 22.6208H46.2815C46.7509 22.6208 47.1315 22.2402 47.1315 21.7708V19.3454Z"
      fill="#00FF7A"
      stroke="black"
      strokeWidth="1.7"
      strokeLinejoin="round"
    />
  </svg>
);

export default HandIcon;
