import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './style';

const Button = ({ fixedWidth, styleType, children, ...rest }) => {
  return (
    <Container styleType={styleType} fixedWidth={fixedWidth} {...rest}>
      {children}
    </Container>
  );
};

Button.propTypes = {
  styleType: PropTypes.oneOf(['ghost', 'ghostVariant']),
  children: PropTypes.string.isRequired,
  fixedWidth: PropTypes.bool.isRequired,
};

Button.defaultProps = {
  styleType: 'ghost',
  fixedWidth: true,
};

export default Button;
