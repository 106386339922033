import React from 'react';

const HouseIcon = () => (
  <svg
    width="100%"
    viewBox="0 0 72 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.6825 51.8571V28.0412H33.4984V17.6698H68.838V51.8571H9.6825Z"
      fill="white"
      stroke="black"
      strokeWidth="1.7"
    />
    <path
      d="M32.2051 27.9778L12.0403 27.3574C10.685 27.3157 9.76312 25.9659 10.2174 24.6883L14.9715 11.3173C15.255 10.5199 16.0097 9.9873 16.8559 9.9873H42.6572C43.1877 9.9873 43.6964 9.77658 44.0714 9.40151L52.0143 1.45863C52.8125 0.660449 54.1127 0.68055 54.8858 1.50302L68.3593 15.8365C69.5593 17.1132 68.6542 19.2063 66.902 19.2063H36.2666C35.162 19.2063 34.2666 20.1018 34.2666 21.2063V25.9788C34.2666 27.1074 33.3332 28.0125 32.2051 27.9778Z"
      fill="#00FF7A"
    />
    <path
      opacity="0.87"
      d="M31.8824 28.0413H11.6518C10.2975 28.0413 9.33474 26.7234 9.74652 25.4332L14.2318 11.3792C14.4964 10.5501 15.2668 9.9873 16.1371 9.9873H41.8889C42.4193 9.9873 42.928 9.77658 43.3031 9.40151L51.246 1.45863C52.0442 0.660449 53.3443 0.68055 54.1174 1.50302L69.1748 17.5214C69.7748 18.1598 69.3222 19.2063 68.4461 19.2063H35.8824C34.7778 19.2063 33.8824 20.1018 33.8824 21.2063V26.0413C33.8824 27.1458 32.987 28.0413 31.8824 28.0413Z"
      stroke="black"
      strokeWidth="1.7"
    />
    <mask id="path-4-inside-1_300_1873" fill="white">
      <rect x="18.1333" y="33.8032" width="6.91428" height="9.9873" rx="1" />
    </mask>
    <rect
      x="18.1333"
      y="33.8032"
      width="6.91428"
      height="9.9873"
      rx="1"
      stroke="black"
      strokeWidth="3.4"
      mask="url(#path-4-inside-1_300_1873)"
    />
    <mask id="path-5-inside-2_300_1873" fill="white">
      <rect x="41.1808" y="23.0477" width="6.91428" height="9.9873" rx="1" />
    </mask>
    <rect
      x="41.1808"
      y="23.0477"
      width="6.91428"
      height="9.9873"
      rx="1"
      stroke="black"
      strokeWidth="3.4"
      mask="url(#path-5-inside-2_300_1873)"
    />
    <mask id="path-6-inside-3_300_1873" fill="white">
      <rect x="55.0096" y="23.0477" width="6.91428" height="9.9873" rx="1" />
    </mask>
    <rect
      x="55.0096"
      y="23.0477"
      width="6.91428"
      height="9.9873"
      rx="1"
      stroke="black"
      strokeWidth="3.4"
      mask="url(#path-6-inside-3_300_1873)"
    />
    <path
      d="M47.3269 51.4726V43.0218C47.3269 40.9003 49.0467 39.1805 51.1682 39.1805V39.1805C53.2897 39.1805 55.0095 40.9003 55.0095 43.0218V51.4726"
      stroke="black"
      strokeWidth="1.7"
    />
    <path
      d="M33.8824 20.7429L33.8825 51.8572"
      stroke="black"
      strokeWidth="1.7"
    />
    <path
      d="M2 13.6913C5.45714 8.65966 16.5968 1.36128 26.9682 13.6913C22.7137 19.331 13.1397 27.0978 2 13.6913Z"
      fill="white"
      stroke="black"
      strokeWidth="1.7"
    />
    <circle
      cx="14.2919"
      cy="13.9881"
      r="3.8992"
      fill="#65EBA6"
      stroke="black"
      strokeWidth="1.7"
    />
  </svg>
);

export default HouseIcon;
