import styled, { css } from 'styled-components';
import { BlankButton } from '@strikelabs/luna';
import { rem } from 'polished';

export const Container = styled(BlankButton)`
  transition: all 0.3s ease-in-out;
  font-size: ${rem(16)};
  border: 1px solid;
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  max-width: 100%;
  ${({ fixedWidth }) => fixedWidth && 'min-width: 325px;'}

  ${({ theme, styleType }) => {
    switch (styleType) {
      case 'ghostVariant':
        return css`
          border-color: ${theme.colors.background};
          color: ${theme.colors.background};

          &:hover {
            border-color: ${theme.colors.accent};
            color: ${theme.colors.accent};
          }
        `;
      case 'blackVariant':
        return css`
          border-color: ${theme.colors.backgroundOffBlack};
          color: ${theme.colors.backgroundOffBlack};

          &:hover {
            color: ${theme.colors.lightIcon};
            background: ${theme.colors.backgroundOffBlack};
          }
        `;
      default:
        return css`
          border-color: ${({ theme }) => theme.colors.secondary};

          &:hover {
            border-color: #848484;
          }
        `;
    }
  }}
`;
