import React from 'react';
import { Box, Button } from '@strikelabs/luna';

import Guide from 'components/Guide';
import Link from 'components/Link';

import {
  Outer,
  Inner,
  Header,
  Subheader,
  GuideRow,
  ButtonContainer,
} from './style';

export const Guides = () => {
  return (
    <Outer>
      <Inner>
        <Box width={[1, 1, 1, 3 / 4]}>
          <Header>We&apos;re in your corner</Header>
          <Subheader>
            We know that selling is personal &ndash; and you need to make the
            right choice for you. Get that you&apos;re-the-boss feeling with our
            buying and selling guides.{' '}
          </Subheader>
        </Box>
        <GuideRow>
          <Guide
            title="The step-by-step guide to selling your home"
            image="guides/step-by-step-sell.png"
            imageAlt="Modern kitchen"
            link="/selling/guides/selling-your-house-tips"
          />
          <Guide
            title="Find your budget with our cost of moving calculator"
            image="guides/cost-of-moving-calc.png"
            imageAlt="Mobile phone"
            link="/cost-of-moving"
          />
          <Guide
            title="What’s the happiest place to live in the UK?"
            image="guides/happiest-place.png"
            imageAlt="Street of houses"
            link="/latest-news/the-happiest-places-to-live-uk"
          />

          <Guide
            title="The complete guide for first-time buyers"
            image="guides/first-time-buyers.png"
            imageAlt="House front"
            link="/buying/guides/first-time-buyers"
          />
          <Guide
            title="Should I sell or should I extend? "
            image="guides/sell-or-extend.png"
            imageAlt="Hallway mirror"
            link="/selling/guides/should-i-sell-or-extend"
          />
          <Guide
            title="Should I move house now? Here’s what to consider."
            image="guides/should-i-move-now.png"
            imageAlt="Moving house"
            link="/selling/guides/should-i-move"
          />
        </GuideRow>
        <ButtonContainer>
          <Link to="/selling/guides">
            <Button styleType="ghost">Read more guides</Button>
          </Link>
        </ButtonContainer>
      </Inner>
    </Outer>
  );
};

export default Guides;
